<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp">

        </div>
        <!--Page header-->
        <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-title">OverTime Approval</div>
            </div>
        </div>

        <div class="row">
            <div class="col-x1-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <form id="fcform" class="form-horizantal form-label-left">
                            <form class="form-horizantal card-body row label-floating px-4" id="fcform">

                                <div class="col-md-6 col-sm-12 col-xs-12 mb-4">
                                    <label class="p-0 form-label col-x1-6 col-md-6 col-lg-6">Employee Name / ID
                                    </label>
                                    <div class="form-group m-0">
                                        <div>
                                            <input type="text" class="form-control " name="employeename"
                                                id="employeename" [(ngModel)]="searchleave" autocomplete="off"
                                                (input)="chkInput($event)" autofocus="autofocus"
                                                placeholder="Search Employee Name">

                                        </div>
                                    </div>
                                </div>


                                <div class="col-md-4 col-sm-4 col-xs-12 mb-4">
                                    <label class="p-0 form-label col-x1-4 col-md-4 col-lg-4">Status</label>
                                    <div class="form-group m-0">
                                        <div>
                                             <div>
                                                 <select id="OvertimeFilters" class="form-control" name="dateFilter"
                                                 [(ngModel)]="statusFilter">
                                                         <option value="all">All</option>
                                                         <option value="Over Time Approve Pending">OverTime Approve Pending</option>
                                                         <option value="Over Time Cancel Pending">OverTime Cancel Pending</option>
                                                    </select>
                                                  </div>          
                                              </div>
                                         </div>
                                     </div>

                                   <div class="col-md-2 col-sm-12 col-xs-12">
                                  <label class="form-label col-xl-2 col-md-2 col-lg-2 m-0 p-0 ">&nbsp;</label>
                                  <button type="button" id="navigatetarget" class="btn btn-primary  wd w-100"
                                  (click)="search()">
                                  <i class="fa fa-search p-0" aria-hidden="true"></i> Search
                                   </button>
                             </div>
                        </form>
                     </form>
                  </div>
             </div>
         </div>
      </div>

      <div class="empty-div-one" style="height: 10px;" id="navigatedest"></div>
      <!-- Row -->
      <div class="row">
          <div class="col-md-12">
              <div class="card">
                  <div class="card-header  border-0">
                      <h4 class="card-title">OverTime Summary</h4>
                  </div>
                  <div class="card-body">
                      <div class="col-xs-12 mobile view">
                          <form action="#">
                              <div class="table-responsive">
                                  <div class="scrollsearchresults" id="scrollength">
                                      <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                          id="hr-leaves">
                                          <thead class="tablerow">
                                              <tr class="rowcolors stickyatTop ">
                                                  <th class="secondcolumn fixedcolumn"><input type="checkbox"
                                                          style="cursor: pointer;" 
                                                          (change)="checkAllSelected($event)" /></th>
                                                          <th class="border-bottom-0 w-5 fixedcolumn">Employee ID</th>
                                                          <th class="border-bottom-0 w-5 secondcolumn">Employee Name</th>
                                                          <th class="border-bottom-0">OT Applied minutes</th>
                                                          <th class="border-bottom-0">OT Approval minutes</th>
                                                          <th class="border-bottom-0">Applied on</th>
                                                          <th class="border-bottom-0">Reason</th>
                                                         <th class="border-bottom-0">Status</th>
                                                         <th class="border-bottom-0">Remark</th>

                                              </tr>
                                          </thead>
                                            <tbody *ngIf="showTable">
                                            <tr *ngFor="let overTimeOut of overTimeOutDetailFlow;let i=index;">
                                                <td class=" secondcolumn fixedcolumn backgroundFW"><input
                                                        type="checkbox" style="cursor: pointer;"
                                                        (change)="checkSelected(overTimeOut,$event)" /></td>
                                                <td class="fixedcolumn backgroundFW">
                                                    {{overTimeOut.employeeId}}
                                                </td>
                                                <td class="secondcolumn backgroundSW">
                                                    <div class="d-flex">
                                                        <div class="me-3 mt-0 mt-sm-2 d-block">
                                                            <h6 class="mb-1 fs-14">{{overTimeOut.employeename}}</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                 <!-- | date:'shortTime' -->
                                                 <td>
                                                    {{overTimeOut.totalMinutes | number:'1.0-0' }}
                                                    <!-- <input autocomplete="off" type="text" style="min-width: 6.7rem;"
                                                    name="OTMin" class="form-control"
                                                    [(ngModel)]="overTimeOutDetailFlow[i].totalMinutes"
                                                    placeholder="OT Minutes"> -->


                                                 </td>
                                                 <td>
                                                    <input autocomplete="off" type="text" style="min-width: 6.7rem;"
                                                    name="OTMin" class="form-control"
                                                    [(ngModel)]="overTimeOut.OTMinutes"
                                                    [value]="overTimeOut.totalMinutes"
                                                    [placeholder]="overTimeOut.totalMinutes | number:'1.0-0' "
                                                    (ngModelChange)="overTimeOut.OTMinutes = $event.trim()">


                                                 </td>
                                                 <td>{{overTimeOut.date | date}}</td>
                                                
                                                 <td class="text-wrap" style="min-width:10vw;max-width: 12vw;">
                                                     {{overTimeOut.reason}}</td>
                                               
                                                 <!-- <td>
                                                     <span *ngIf=" extraTimeOut.status === 'Pending'"
                                                         class="badge badge-warning">{{ extraTimeOut.status}}</span>
                                                     <span *ngIf=" extraTimeOut.status === 'APPROVED'"
                                                         class="badge badge-success">{{ extraTimeOut.status}}</span>
                                                     <span *ngIf=" extraTimeOut.status === 'REJECTED'"
                                                         class="badge badge-danger">{{ extraTimeOut.status}}</span>
                                                 </td> -->
                                                 <td>
                                                     <span style="font-size: 13px; font-weight: 500;" [ngClass]="
                                                         overTimeOut.status === 'Extra Time Out Cancel Pending' ? 'bg-custom-warning2 text-custom-dark' :
                                                         overTimeOut.status === 'Approved' ? 'bg-custom-green text-custom-dark' :
                                                         overTimeOut.status === 'Rejected' ? 'bg-custom-red text-custom-dark' :
                                                         overTimeOut.status === 'Partial Approve 1' ? 'bg-custom-orange1 text-custom-dark' :
                                                         overTimeOut.status === 'Partial Approve 2' ? 'bg-custom-orange2 text-custom-dark' :
                                                         'bg-custom-warning text-custom-dark'
                                                       " class="badge">
                                                         {{ overTimeOut.status }}
                                                     </span>
                                                 </td>
                                                  <td><input autocomplete="off" type="text" style="min-width: 9.7rem;"
                                                         name="remark" class="form-control"
                                                         [(ngModel)]="overTimeOut.remark"
                                                         placeholder="Comments for Rejection"></td>
                                             </tr>
                                          </tbody> 
                                     </table>
                                 </div>
                             </div>
                             <div *ngIf="showTable" class="pro-fab btn-float-right">
                                 <button type="button" (click)="onApproveHandler()"
                                     class="btn btn-success">Approve</button>
                                 <button type="button" (click)="onRejectHandler()"
                                     class="btn btn-danger ms-2">Reject</button>
                             </div>
                         </form>
                     </div>
                 </div>
             </div>
         </div>
     </div>
     <!-- End Row-->

 </div><!-- end app-content-->
</div>

                                




