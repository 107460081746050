import { Component, OnInit } from '@angular/core';
import { LeaveEncashmentInfo } from '../models/employee-leave-encash-info.model';
import { SessionService } from '../services';
import { EmployeeLeaveEncashService } from '../services/employee.leave.encash.service';
import { AlertService } from 'src/app/services';
import { Router } from '@angular/router';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { tap } from 'rxjs';

declare var $: any


@Component({
	selector: 'app-employee-leave-encash',
	templateUrl: './employee-leave-encash.component.html',
	styleUrls: ['./employee-leave-encash.component.css']
})
export class EmployeeLeaveEncashmentComponent implements OnInit {

	applieddate: string;
	addOrUpdate: boolean = true;
	empLeaveEncashInfo = new LeaveEncashmentInfo(-1, null, 1, "","");
	totalLeaveCount: number = 0;
	totalLeaveDates = [];
	leaveTypesList = [{ leaveType: "", leaveName: "" }]
	interleaveHoliday: boolean = false;
	leaveTypeMap = new Map();
	leaveBalance_Count: any;
	totalleave_count: any;
	noofdays: any;
	unqiueEmpid = Number(sessionStorage.getItem('empId'));
	leavetype: string;
	encashmentList: any[] = [];
	id: any;
	isEditing = false
	maxLeaveEncash: any;
	ifPersent: boolean;
	isLeavePresent : boolean;
	leavetypeselected: any[] = [];
	encashmentDataList: any[] = [];
	savedLeaveType: string = ''; // This will store the previously saved leave type
  isInputFieldDisabled: boolean = true; // Initially, the input field is disabled

	constructor(private empLeaveEncashService: EmployeeLeaveEncashService, private alertService: AlertService,
		private session: SessionService, private datepipe: DatePipe, private router: Router, private titleUpperCase: TitleCasePipe) {
		//const currentDate = new Date();
		// const year = currentDate.getFullYear();
		// const month = currentDate.getMonth() + 1;
		// this.applieddate = `${year}-${month.toString().padStart(2, '0')}-01`;
		let date = new Date();
		this.applieddate = date.toISOString().substring(0, 10);

	}
	ngOnInit(): void {
		var info = this.empLeaveEncashInfo;

		// const today = new Date();
		// const year = today.getFullYear();
		// const month = (today.getMonth() + 1).toString().padStart(2, '0');
		// const day = today.getDate().toString().padStart(2, '0');   

		// const today = new Date();

		this.resetNOD()
		this.getLeaves();
		// this.getEncashmentLeaveApplied();
		this.getEncashmentLeaveApplied().subscribe();
		//  this.getleaveBalance();

	}


	getLeaves() {

		this.empLeaveEncashService.getAllLeaveTypes().subscribe(

			data => {
				this.leaveTypesList = data.encashableLeaveType
				console.log("Data Leaves ", this.leaveTypesList);
				this.setLeaveMap();
				this.getleaveBalance();
			},
			error => {
				console.log("Error ", error);
			}
		)
	}

	onClear() {
		this.addOrUpdate = true;
		this.totalLeaveCount = 0;
		this.empLeaveEncashInfo = new LeaveEncashmentInfo(-1, null, 1, "","");
		document.getElementById('leavetypes')?.removeAttribute("empty")
		const nod = document.getElementById('noofLeave') as HTMLInputElement;
		nod.value = '';
		this.resetNOD()
	}

	setLeaveMap() {
		this.leaveTypesList.forEach(type => {
			this.leaveTypeMap.set(type.leaveType, type.leaveName)
		})
		console.warn(this.leaveTypeMap, "leave map")
		// this.getEmployeeLeaveOverview()
	}

	resetNOD() {
		this.empLeaveEncashInfo.NOD = null;

	}

	getLeaveName(leaveType: any) {
		console.warn(leaveType, "leave type", this.leaveTypeMap.get(leaveType))
		return this.leaveTypeMap.get(leaveType)
	}

	getleaveBalance() {
		let uempid = this.session.getempid();
		let date = new Date();
		this.empLeaveEncashInfo.AppliedDate = date.toISOString().substring(0, 10);
		let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
		let firstDayOfPreviousMonth = new Date(firstDay.getFullYear(), firstDay.getMonth() - 1, 1); //first day of previous month
		let leavetype = this.empLeaveEncashInfo.leaveType;
		// let indic = this.empLeaveEncashInfo.halfFullIndic; 

		//check opening leave-balance

		this.empLeaveEncashService.getleavebalance(Number(uempid), leavetype, this.datepipe.transform(this.empLeaveEncashInfo.AppliedDate, 'yyyy-MM-dd')).subscribe({
			next: (res) => {
				this.leaveBalance_Count = res.openingleaveBalance;
				this.totalLeaveCount = res.encashableLeave;
				this.maxLeaveEncash = res.maxLeaveEncash;

				console.log(" get leave balance function  ");
				console.log(" ");
				console.log(" leaveBalance_Count ", this.leaveBalance_Count);
				console.log(" totalLeaveCount ", this.totalLeaveCount);

				// let count = this.calculateLeave();

				if (this.empLeaveEncashInfo.NOD >= this.maxLeaveEncash) {


					this.alertmessages(" Can't apply for Encashment. You have no sufficient encashable leaves for this leave type.", "danger")
					this.onClear()
				}
				// else if (this.leaveBalance_Count < this.totalLeaveCount) {
				// 	this.alertmessages(" Can't apply for Encashmen. You have no sufficient leaves for this leave type.", "danger")
				// 	this.onClear()
				// }
				//this.alertmessages("Successfully Updated", "success");

			}, error: (err) => {
				console.log(err)

			}

		})
	}




	onSubmit() {

		console.log(" on submit 1 ");

		// this.empLeaveEncashInfo.empId = this.uniqueEmployeeId;
		this.empLeaveEncashInfo.empId = parseInt(this.session.getempid());
		let today = new Date();
		this.empLeaveEncashInfo.AppliedDate = today.toISOString().substring(0, 10);
		console.log("Submit Data ", this.empLeaveEncashInfo);

		this.noofdays = this.empLeaveEncashInfo.NOD;

		if (this.empLeaveEncashInfo.leaveType == "") {
			this.alertmessages("Please Select Leave Type", "danger")
			return;
		}
		if (this.noofdays == null || this.noofdays == 0) {
			this.alertmessages("Please Select Number of Days", "danger")
			return;
		}

		// Check if the selected date is a Sunday
		const selectedDate = new Date(this.empLeaveEncashInfo.AppliedDate);

		console.log(" on submit 2 ");

		console.log(this.empLeaveEncashInfo.AppliedDate);


		this.empLeaveEncashInfo.NOD = this.empLeaveEncashInfo.NOD.valueOf();

		console.log(" on submit  4 ");
		console.log(" ");
		console.log(" leaveBalance_Count ", this.leaveBalance_Count);
		console.log(" totalLeaveCount ", this.totalLeaveCount);

		this.unqiueEmpid = this.empLeaveEncashInfo.empId;
		this.applieddate = this.empLeaveEncashInfo.AppliedDate;

		this.leavetype = this.empLeaveEncashInfo.leaveType;
		if(this.noofdays > this.maxLeaveEncash ){
			this.alertmessages(" Can't apply for Encashment. You have only " + this.maxLeaveEncash + " leaves to encash", "danger")

			return;

		}

		this.empLeaveEncashService.saveApplyLeaveEncashment(this.unqiueEmpid, this.applieddate, this.noofdays, this.leavetype).subscribe(
			data => {
				this.encashmentList=data.encashableLeaveDetail;



				this.alertmessages("Successfully updated", "success")
				this.onClear()
				this.resetNOD()
				//this.getEncashmentLeaveApplied();

			},

		)

	}


	alertmessages(message: string, sign: string): void {
		let alertDiv = document.createElement('div');
		alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
	<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
		let alertcomp = document.querySelector('.alertcomp');
		alertcomp.append(alertDiv);
		document.body.scrollTop = document.documentElement.scrollTop = 0;

		setTimeout(function () {
			alertcomp.removeChild(alertDiv);
		}, 5000);
	}



	onChangeLeaveTypes(leaveType: any) {
		console.log("Leave Type ", leaveType);
		
		console.log(this.leaveTypesList);
		this.empLeaveEncashInfo.leaveType = leaveType
		this.getEncashmentLeaveApplied().subscribe(() => {
			// Now, you can access this.leavetypeselected after the API call
			console.log("Encashment Leave Applied Type: ", this.leavetypeselected);
		
			// Check if the selected leave type matches the previously saved leave type
			if (this.leavetypeselected.includes(leaveType)) {
			  this.isInputFieldDisabled = true; // Disable the input field if it's the same as the applied leave type
			} else {
			  this.isInputFieldDisabled = false; // Enable the input field if it's a new leave type
			}
		
			console.log(this.leaveTypesList);
			// Fetch leave balance based on selected leave type 
			this.getleaveBalance();
		  });
		}

	getEncashmentLeaveApplied() {
		return this.empLeaveEncashService.getEncashmentLeaveApplied(this.unqiueEmpid).pipe(
			tap(data => {
			this.encashmentList = data.encashableLeaveDetail;
			console.log("encashment details :", this.encashmentList, "and length ", this.encashmentList.length);
			// if the leagnth is = 0 or emplty thne we should allow user to enter 


			// Initialize an empty array to store all leave types
            this.leavetypeselected = [];

            // Loop through encashmentList and collect all leave types
            this.encashmentList.forEach(item => {
                this.leavetypeselected.push(item.leaveType);
            });

            console.log("Selected leave types: ", this.leavetypeselected);
			
		})
	);
  }


	editNoofDay(info: any) {
		console.log("Clicked edit ", info);
	
		let leaveType = info.leaveType;  
		let isFound = false;  
	
		// Check if the leave type exists in the encashmentList
		this.encashmentList.forEach(item => {
			console.log("Comparing with leave type: ", item.leaveType);
			if (item.leaveType === leaveType) {
				isFound = true;  // A match is found
			}
		});
	
		// Determine if we have only one record in the list
		const isSingleRecord = this.encashmentList.length === 1;
	
		// Set the state based on whether there's only one record
		this.isInputFieldDisabled = isSingleRecord; // Disable leave type if there's only one record
	
		console.log("Selected leave type in list", leaveType);
	
		// If there's only one record, enable the number of encashment field
		if (isSingleRecord) {
			this.isInputFieldDisabled = false; // Enable the input field for encashment
		}
	
		this.addOrUpdate = false;  // On click edit, make submit button an update button
		this.empLeaveEncashInfo.empId = info.empId;
		this.empLeaveEncashInfo.AppliedDate = info.applieddate;
		this.empLeaveEncashInfo.NOD = info.nod;
		this.empLeaveEncashInfo.leaveType = info.leaveType;
	
		// Optional: You can keep this logic if needed to show the user some feedback
		if (isFound) {
			console.log("Leave type found in encashment list.");
		} else {
			console.log("Leave type not found in encashment list.");
		}
	
		this.getleaveBalance();
	}
	

}
